import { Grid, Link, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  getPortalSignInUrl,
  getPortalSignUpStudentUrl,
  getPortalStudentForgotPasswordUrl,
  getPortalStudentSignInUrl,
  getPortalUrl,
} from '../utils/urls';

const useStyles = makeStyles({
  container: {
    marginTop: 150,
  },
  subheader: {
    marginTop: 40,
  },
  unorderedList: {
    listStyleType: 'none',
    marginTop: 15,
    paddingLeft: 0,
  },
  body1: {
    marginTop: 40,
  },
});

const SiteMap: React.FC = () => {
  const classes = useStyles();

  const getLink = ({
    href,
    text,
  }: {
    href: string;
    text: string;
  }): React.ReactNode => (
    <Typography variant="body1">
      <Link variant="inherit" color="inherit" href={href}>
        {text}
      </Link>
    </Typography>
  );

  return (
    <Layout isHeaderSticky={false}>
      <SEO title="Site Map" />
      <Grid container spacing={3} className={classes.container}>
        <Grid item md={2} sm={1} />
        <Grid item xs={10} md={8}>
          <Typography variant="h3">Site Map</Typography>
          <Typography variant="h5" className={classes.subheader}>
            Links for students:
          </Typography>
          <ul className={classes.unorderedList}>
            <li>
              {getLink({
                href: getPortalSignUpStudentUrl(),
                text: 'Student Sign Up',
              })}
            </li>
            <li>
              {getLink({
                href: getPortalStudentSignInUrl(),
                text: 'Student Log In',
              })}
            </li>
            <li>
              {getLink({
                href: getPortalStudentForgotPasswordUrl(),
                text: 'Forgot Password',
              })}
            </li>
            <li>
              {getLink({
                href:
                  'https://evidencebasedcourses.zendesk.com/hc/en-us/requests/new',
                text: 'Contact',
              })}
            </li>
            <li>
              {getLink({
                href: '/terms',
                text: 'Terms',
              })}
            </li>
            <li>
              {getLink({
                href: '/privacy',
                text: 'Privacy',
              })}
            </li>
          </ul>

          <Typography variant="h5" className={classes.subheader}>
            Additional links for teachers:
          </Typography>
          <ul className={classes.unorderedList}>
            <li>
              {getLink({
                href: '/for-educators',
                text: 'For Educators',
              })}
            </li>
            <li>
              {getLink({
                href: getPortalSignInUrl(),
                text: 'Teacher Log In',
              })}
            </li>
            <li>
              {getLink({
                href: 'https://evidencebasedcourses.zendesk.com/hc/en-us',
                text: 'Help Center',
              })}
            </li>
          </ul>
          <Typography variant="body1" className={classes.body1}>
            If any of the information is not accessible to you, please contact
            us so that we can provide alternatives:
          </Typography>
          <ul className={classes.unorderedList}>
            <li>
              <Typography variant="body1">Phone: (805) 272-0153</Typography>
            </li>
            <li>
              <Typography variant="body1">
                Email: support@findingfocus.app
              </Typography>
            </li>
          </ul>
        </Grid>
        <Grid item md={2} sm={1} />
      </Grid>
    </Layout>
  );
};

export default SiteMap;
